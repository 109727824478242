type ServiceType = {
  id: string;
  title: string;
  description: string;
  icons: {url: string};
};

export const useService = () => {
  const {$api} = useNuxtApp();

  const services = useState<ServiceType[]>("services", () => []);

  /**
   * Return services if exist => :::
   */
  if (services.value?.id) {
    return {
      services,
      loading: false,
    };
  }

  const {data, pending} = useAsyncData(
    "services",
    async () => {
      const {
        data: {
          data: {services},
        },
      } = await $api.get("/services");

      return services;
    },
    {server: false, lazy: false, default: () => []}
  );

  watch(
    () => data?.value,
    (value) => {
      services.value = value;
    }
  );

  return {
    services,
    loading: pending,
  };
};
